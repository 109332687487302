import styled, { css } from "styled-components";
import { LayoutProps, SpaceProps, layout, space } from "styled-system";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { breakpoints } from "../../../../../../shared-logic/themes/toyotaTheme";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import EmissionLabelWrapper from "../../../../../../../common-deprecated/styles/v2/toyota/components/EmissionLabelWrapper";
import { IconWrapper } from "../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import * as EmissionLabelStyles from "../../../../../../../common-deprecated/components/eco-labels/styles/EmissionLabelStyles";
import { Monthly } from "../../price/style/CarResultPriceStyles";
import { loadingBackgroundCss } from "../../../../../../../common-deprecated/styles/lexus/Background";

export const Wrapper = styled.article<{ isSimilarCar?: boolean }>`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.space[3] / 2}px;
    position: relative;
    background-color: ${theme.colors.primaryWhite};
    border: 0;
    box-shadow: 0px 0.6793199777603149px 3.623039960861206px 0px rgba(0, 0, 0, 0.02),
        0px 1.8782328367233276px 10.017241477966309px 0px rgba(0, 0, 0, 0.03),
        0px 4.522060871124268px 24.117658615112305px 0px rgba(0, 0, 0, 0.05), 0px 15px 80px 0px rgba(0, 0, 0, 0.04);
    transition: box-shadow 0.3s ease-in, outline 0.3s ease-out;
    cursor: pointer;

    &:is(:hover, :focus) {
        box-shadow: 0 0 0 1px ${theme.colors.grey3};
    }

    @media screen and (min-width: ${breakpoints.md}) {
        grid-row: auto / span var(--usc-car-result-subgrid-row, 6);
        display: grid;
        grid-template-rows: subgrid;
        grid-template-columns: minmax(0, 1fr);
        grid-row-gap: ${theme.space[3] / 2}px;

        @supports not (grid-template-rows: subgrid) {
            display: flex;
            flex-direction: column;
            grid-row: auto;
            row-gap: ${theme.space[3] / 2}px;
        }
    }

    @supports not (row-gap: 0) {
        header {
            padding-top: 0;
        }

        & > *:not(:nth-last-child(1)) {
            margin-bottom: ${theme.space[3] / 2}px;
        }
    }

    ${({ isSimilarCar }) =>
        isSimilarCar &&
        css`
            display: flex;
            align-self: stretch;
            width: 100%;

            @media screen and (min-width: ${breakpoints.md}) {
                display: flex;
                flex-direction: column;
                row-gap: ${theme.space[3] / 2}px;
                align-self: stretch;
                width: 100%;
            }
        `}

    @supports not (padding-inline: 0) {
        @media screen and (min-width: ${breakpoints.md}) {
            display: flex;
            height: 100%;
        }
    }
`;

export const Header = styled.header`
    position: relative;
    padding-inline: ${theme.space[3]}px;

    @supports not (padding-inline: 0) {
        padding-left: ${theme.space[3]}px;
        padding-right: ${theme.space[3]}px;
    }
`;

export const SaleTypeBadges = styled.div`
    &:not(:empty) {
        margin-block-end: ${theme.space[3] / 2}px;
        padding-inline: ${theme.space[3]}px;

        @supports not (padding-inline: 0) {
            margin-bottom: ${theme.space[3] / 2}px;
            padding: 0 ${theme.space[3]}px;
        }
    }
`;

export const Title = styled.h3`
    display: inline-block;
    margin-bottom: ${theme.space[1] / 2}px;
    font-family: ${theme.fonts.semiBold};
    font-size: 2rem;
    line-height: 2.8rem; /* Increased line-height from 2.4 -> 2.8 because it will be cut off differently */
    text-transform: none;
    color: ${theme.colors.primaryBlack};

    @supports (display: -webkit-box) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;

export const SubTitle = styled.div`
    width: 100%;
    margin: 0 0 ${theme.space[1] / 2}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${theme.colors.grey7};
`;

export const Location = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.space[1] / 2}px;
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.primaryBlack};

    svg {
        position: relative;
        top: 3px;
        margin-inline-end: ${theme.space[1] / 2}px;
    }

    @supports not (margin-inline-end: 0) {
        margin-${getDirection("right")}: ${theme.space[1] / 2};
    }
`;

export const Specs = styled.div<{ isSimilarCar?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[2]}px;
    padding: 0 ${theme.space[3]}px;

    @supports not (grid-template-rows: subgrid) {
        margin: ${theme.space[3] / 2}px 0;
    }

    ${({ isSimilarCar }) =>
        isSimilarCar &&
        css`
            margin: ${theme.space[3] / 2}px 0;
        `};

    @supports not (row-gap: 0) {
        & > * {
            &:not(:nth-last-child(1)) {
                margin-bottom: ${theme.space[2]}px;
            }
        }
    }
`;

export const SpecsList = styled.ul<{ isLarge?: boolean; lineclamp?: boolean; limitedList?: boolean }>`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: ${theme.space[1]}px;
    grid-column-gap: ${theme.space[3] / 2}px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    ${({ isLarge }) =>
        isLarge &&
        css`
            @media screen and (min-width: ${breakpoints.md}) {
                height: 88px;
            }
        `};

    ${({ limitedList }) =>
        limitedList &&
        css`
            li {
                display: none;

                &:nth-child(-n + 4) {
                    display: flex;
                }
            }
        `};

    /* EfficiencyClassIcon */
    ${EmissionLabelWrapper} {
        position: relative;
        width: 100%;
        height: 24px;

        svg {
            transform: scale(0.65) translateX(-20px) translateY(-10px);
        }
    }

    /* EmissionLabel */
    ${EmissionLabelStyles.Wrapper} {
        position: relative;
        width: 100%;
        top: 0;

        /* Webcomponent */
        dxp-emission-label {
            position: absolute;
            top: -8px;
            left: -8px;
        }
    }
`;

export const SpecListItem = styled.li<{ isEfficiencyClass: boolean; lineclamp?: boolean }>`
    position: relative;

    margin-bottom: 0;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    hyphens: auto;
    color: ${theme.colors.primaryBlack};
    text-wrap: balance;

    @supports not (hyphens: auto) {
        word-wrap: break-word;
    }

    ${({ isEfficiencyClass }) =>
        !isEfficiencyClass &&
        css`
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        `};

    ${({ lineclamp }) =>
        lineclamp &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        `};
`;

export const SpecValueWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space[1] / 2}px;
`;

export const SpecLabel = styled.div`
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;
    margin-bottom: ${theme.space[1] / 2}px;
`;

export const SpecValue = styled.div`
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
`;

export const ButtonMoreSpecs = styled.button`
    display: block;
    position: relative;
    width: 100%;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.grey7};

    span {
        position: relative;
        display: inline-block;
        padding: 0 ${theme.space[1]}px 3px ${theme.space[1]}px;
        background-color: ${theme.colors.primaryWhite};
        border: 1px solid ${theme.colors.grey3};
        border-radius: 0.3rem;
        z-index: 1;
    }

    &::after {
        display: block;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        width: 100%;
        height: 1px;
        content: "";
        background-color: ${theme.colors.grey3};
        z-index: 0;
    }

    &:hover {
        color: ${theme.colors.primaryBlack};
        span {
            color: ${theme.colors.primaryBlack};
            background-color: ${theme.colors.grey2};
            border-color: ${theme.colors.grey2};
        }
    }
`;

export const MoreSpecsDialogCloseButton = styled.button`
    display: flex;
    position: absolute;
    top: 12px;
    right: ${theme.space[3]}px;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: ${theme.colors.primaryWhite};
    background-color: ${theme.colors.primaryBlack};
    z-index: 1071;
`;

export const ImageWrapper = styled.div`
    position: relative;
    // We disable the touch-action here to disable the scrolling/scroll snapping of the parent component (the similar cars slider)
    // So the user scrolls only into the used car results slider and not in the similar cars slider when scrolling the images
    // Ideally the used car results slider should be refactored to scroll snapping. Once that happened we can use 'overscroll-behavior-x' instead
    touch-action: none;
`;

export const CtaGroup = styled.div<{ isSimilarCar?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    gap: ${theme.space[1]}px;
    align-self: flex-end;
    padding: 0 ${theme.space[3]}px;

    @supports not (grid-template-rows: subgrid) {
        margin: ${theme.space[2]}px 0 ${theme.space[2]}px;
    }

    ${({ isSimilarCar }) =>
        isSimilarCar &&
        css`
            flex-grow: 1;
            margin: ${theme.space[2]}px 0 ${theme.space[2]}px;
        `};

    @supports not (gap: 0) {
        & > div {
            margin-bottom: ${theme.space[1]}px;
        }
    }
`;

export const MoreSpecsDialog = styled.div<{
    carImageAspectRatio43?: boolean;
    hidden: boolean;
    similarCarResult?: boolean;
}>`
    display: ${({ hidden }) => (hidden ? "none" : "block")};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1060;
    cursor: default;

    @media screen and (min-width: ${breakpoints.md}) {
        position: absolute;
        padding-top: ${({ carImageAspectRatio43 }) => (carImageAspectRatio43 ? "75%" : "56.25%")};
    }

    ${Header} {
        flex-grow: 0;
        padding-top: 0;
        padding-left: 0;
    }

    ${CtaGroup} {
        padding: 0;
        margin-top: auto;
    }

    @supports not (row-gap: 0) {
        & > * {
            &:not(:nth-last-child(1)) {
                margin-bottom: ${theme.space[2]}px;
            }
        }
    }

    ${(similarCarResult) =>
        similarCarResult &&
        css`
            position: absolute;
        `};
`;

export const MoreSpecsDialogInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${theme.space[3] / 2}px ${theme.space[3]}px ${theme.space[2]}px ${theme.space[3]}px;
    row-gap: ${theme.space[2]}px;
    background-color: ${theme.colors.primaryWhite};
`;

export const DeliveryBadgeWrapper = styled.div`
    &:not(:empty) {
        margin-top: ${theme.space[2]}px;
        padding: 0 ${theme.space[3]}px;
    }
`;

export const DeliveryBadge = styled.div<{ hidden?: boolean }>`
    display: ${({ hidden }) => (hidden ? "none" : "flex")};
    align-items: center;
    justify-content: center;
    gap: ${theme.space[1]}px;
    padding: ${theme.space[1]}px ${theme.space[3]}px;
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.primaryBlack};
    background-color: ${theme.colors.grey2};
    border: 1px solid ${theme.colors.grey2};
`;

export const Prices = styled.div<{ isSimilarCar?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 ${theme.space[3]}px;

    @supports not (grid-template-rows: subgrid) {
        margin-top: auto;
    }

    ${({ isSimilarCar }) =>
        isSimilarCar &&
        css`
            margin-top: auto;
        `};

    ${Monthly} {
        font-family: ${theme.fonts.regular};
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
`;

export const Button = styled.button<{ isActive?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    column-gap: ${theme.space[1]}px;
    padding: ${theme.space[1]}px ${theme.space[3]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${({ isActive }) => (isActive ? theme.colors.primaryWhite : theme.colors.primaryBlack)};
    background-color: ${({ isActive }) => (isActive ? theme.colors.grey7 : "transparent")};
    border: 0;
    transition: background-color 0.3s ease-in;

    &:not(:only-child) {
        max-width: 50%;
    }

    &:hover {
        ${({ isActive }) =>
            !isActive &&
            css`
                background-color: ${theme.colors.grey2};
            `};
    }

    @supports not (gap: 0) {
        ${IconWrapper} {
            margin-${getDirection("right")}: ${theme.space[1]}px;
        }
    }
`;

export const DeliveryAndButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
`;

export const ButtonGroup = styled.div<{ isSimilarCar?: boolean }>`
    display: flex;
    margin-top: auto;
    background-color: ${theme.colors.grey1};
    border-top: 1px solid ${theme.colors.grey2};

    ${Button}:not(:only-child):nth-last-child(1) {
        border-left: 1px solid ${theme.colors.grey2};
    }

    @supports not (grid-template-rows: subgrid) {
        margin-top: 0;
    }

    ${({ isSimilarCar }) =>
        isSimilarCar &&
        css`
            margin-top: 0;
        `};

    &:empty {
        display: none;
    }
`;

export const LoadBar = styled.div<LayoutProps & SpaceProps>`
    ${layout};
    ${space};
    display: inline-block;
    ${loadingBackgroundCss}
`;
