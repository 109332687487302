import React, { useState } from "react";
import { ModalViewType } from "../../../../types/CommonTypes";
import { ModalVersionType } from "../../../../utils/modalConstants";
import { IntegratedDebugModal } from "./IntegratedDebugModal";
import { useCommonSelector } from "../../../../redux/commonStore";
import { ClearCacheBodyType } from "../../../../server/v2/endpoints/ClearCacheEndpoint";

export type API_REQUEST_STATUS = "idle" | "loading" | "succeeded" | "failed";

const ClearCacheModal = (props: ModalViewType<ModalVersionType>): JSX.Element => {
    const { close, show } = props;
    const country = useCommonSelector((state) => state.commonSettings.country);
    const language = useCommonSelector((state) => state.commonSettings.language);
    const resourcePath = useCommonSelector((state) => state.commonSettings.resourcePath);

    const [status, setStatus] = useState<API_REQUEST_STATUS>("idle");
    const [response, setResponse] = useState<string>();

    return (
        <IntegratedDebugModal props={{ show, close, variant: "cache" }}>
            <div>
                <header>
                    <h1>
                        Clear the cache for {country}-{language}
                    </h1>
                </header>
                <section>
                    <button
                        type="button"
                        disabled={status === "loading"}
                        onClick={() => {
                            if (status !== "loading") {
                                setStatus("loading");
                                setResponse(undefined);
                                fetch(`${resourcePath}/clear-cache`, {
                                    method: "POST",
                                    headers: { "Content-Type": "application/json" },
                                    body: JSON.stringify({ country, language } as ClearCacheBodyType),
                                })
                                    .then((res) => res.json())
                                    .then((json) => {
                                        setResponse(JSON.stringify(json));
                                        setStatus("succeeded");
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        setStatus("failed");
                                    });
                            }
                        }}
                    >
                        Clear cache
                    </button>
                    <div>
                        {status === "loading" && <p>Clearing cache</p>}
                        {status === "succeeded" && <p>Cache cleared: {response}</p>}
                        {status === "failed" && <p>Cache clear failed, you can try again</p>}
                    </div>
                </section>
            </div>
        </IntegratedDebugModal>
    );
};

export default ClearCacheModal;
