import { ModalMapType } from "../../../types/CommonTypes";
import {
    CommonModalIdsType,
    MODAL_CACHE_CLEAR,
    MODAL_CONTENT,
    MODAL_ERRORS,
    MODAL_FINANCE_DISCLAIMER,
    MODAL_OK,
    MODAL_REQUEST_LOG,
    MODAL_TOOLS,
    MODAL_VERSION,
} from "../../../utils/modalConstants";
import OkModal from "./OkModal";
import ContentModal from "./ContentModal";
import FinanceDisclaimerModal from "./FinanceDisclaimerModal";
import ErrorsModal from "../status/ErrorsModal";
import ToolsModal from "../../../features/new-cars/debug-modal/components/ToolsModal";
import VersionModal from "../../../features/new-cars/debug-modal/components/VersionModal";
import RequestLogModal from "../../../features/new-cars/debug-modal/components/RequestLogModal";
import ClearCacheModal from "../../../features/new-cars/debug-modal/components/ClearCacheModal";

export const aemToyotaCommonModalMap: ModalMapType<CommonModalIdsType> = {
    [MODAL_OK]: OkModal,
    [MODAL_CONTENT]: ContentModal,
    [MODAL_FINANCE_DISCLAIMER]: FinanceDisclaimerModal,
    [MODAL_VERSION]: VersionModal,
    [MODAL_REQUEST_LOG]: RequestLogModal,
    [MODAL_CACHE_CLEAR]: ClearCacheModal,
    [MODAL_ERRORS]: ErrorsModal,
    [MODAL_TOOLS]: ToolsModal,
};
